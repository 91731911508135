import { useEmailStore } from "@sp-state/useEmailStore";
import { navigate } from "gatsby";

export const useSignUpFormNavigate = () => {
  const { setEmail } = useEmailStore();

  return function (email: string, destination?: string) {
    setEmail(email);
    navigate(destination || "/sign-up/");
  };
};
