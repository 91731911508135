import {
  ReturnData,
  isExcludeOrInclude,
  filterModals,
  ShowType,
  urlsContainCurrentUrl,
} from "./helpers";
import { ModalType } from "../../assets/Types/AcfGroupTypes/EmailCaptureModal";

export const getModalTrigger = (
  modalsReapeter: ModalType[],
  pathname: string,
  showType: ShowType,
  callback: (returnData: ReturnData) => void
) => {
  let modal = null;

  const foundModals = filterModals(modalsReapeter, showType).find(modal => {
    const condition = modal.conditionsRepeater[0] || {};
    let foundTrigger = false;

    if (
      isExcludeOrInclude(condition.excludeOrIncludePages, "Exclude") &&
      condition.excludeVisitorPageUrls?.length > 0 &&
      !urlsContainCurrentUrl(
        condition.excludeVisitorPageUrls,
        "pageUrls",
        pathname
      )
    ) {
      foundTrigger = true;
    } else if (
      isExcludeOrInclude(condition.excludeOrIncludePages, "Include") &&
      condition.includeVisitorPageUrls?.length > 0 &&
      urlsContainCurrentUrl(
        condition.includeVisitorPageUrls,
        "pageUrls",
        pathname
      )
    ) {
      foundTrigger = true;
    }

    return foundTrigger;
  });
  if (foundModals) {
    modal = {
      modalTrigger: foundModals.ctaId,
      timeToWait: foundModals.conditionsRepeater[0].jsInactiveTimeToWait * 1000,
      visitorCookie: foundModals.conditionsRepeater[0].excludeVisitorCookie,
      viewedPages: foundModals.conditionsRepeater[0].sessionPageViews,
      showType: foundModals.conditionsRepeater[0].showType,
      onExitAttemp: foundModals.conditionsRepeater[0].onExitAttemp,
    };
  }

  return callback(modal);
};
