import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
/* Styles */
import styles from "./LuminelloModal.module.scss";

type LuminelloModalPropsType = {
  modal: Queries.builder_LuminelloModalData;
  onCloseModal: () => void;
};

const LuminelloModal: React.FC<LuminelloModalPropsType> = ({
  modal,
  onCloseModal,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as HTMLDivElement)
      ) {
        onCloseModal();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      data-testid="modal"
      className={`${styles.modalContainer}
         ${styles.modalContainerActive}`}
    >
      <div ref={modalRef} className={styles.modalLayout}>
        <div className={`${styles.modalContent}  ${styles.modalContentActive}`}>
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <h5 data-testid="modal-header" className={styles.modalBodyTitle}>
                {modal.modalHeadline}
              </h5>
              <div
                data-testid="div:BtnClose"
                className={styles.close__btn}
                onClick={onCloseModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M21.4309 19.1908C22.0733 19.8333 22.0733 20.8756 21.4309 21.5181C20.7884 22.1607 19.7461 22.1606 19.1035 21.5181L11.0012 13.3554L2.84119 21.5154C2.19874 22.1578 1.15639 22.1578 0.513881 21.5154C-0.128631 20.873 -0.128563 19.8306 0.513881 19.1881L8.6766 11.0309L0.511618 2.80917C-0.130826 2.16672 -0.130826 1.12437 0.511618 0.481859C1.15406 -0.160654 2.19641 -0.160585 2.83893 0.481859L11.0012 8.70629L19.1611 0.546315C19.8036 -0.0961287 20.8459 -0.0961287 21.4884 0.546315C22.131 1.18876 22.1309 2.23111 21.4884 2.87362L13.3257 11.0309L21.4309 19.1908Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            {modal.modalSubcopy && (
              <div
                data-testid="modal-subheader"
                className={`${styles.modalBodySubtitle} ${styles.modalSubTitlePadding}`}
                dangerouslySetInnerHTML={{ __html: modal.modalSubcopy }}
              />
            )}

            <div className={styles.modalFooter}>
              <Link
                to={modal.modalLinkUrl || "#"}
                className={styles.cta}
                data-testid="content4-column-grid-cta"
                onClick={onCloseModal}
              >
                {modal.modalLinkCopy}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LuminelloModal;
