import React, { useContext } from "react";
import { FormContext } from "../../../contexts/FormOnlyEmail.context";
import { EmailOnlyFormProps } from "./Email.type";
import BtnWithArrow from "../../Buttons/BtnWithArrow";
import InputField from "../../Forms/FormFields/InputField";
import emailOnlyStyles from "./EmailOnlyForm.module.scss";
import setRouteName from "../../../utils/getLocationName";
import Button from "../../Buttons/Button";

const EmailOnlyForm: React.FC = () => {
  const {
    buttonText,
    buttonStyle,
    spinnerColor,
    isLoading,
    handleSubmit,
    handleBlur,
    handleChange,
    email,
    withArrow,
    placeholder,
    inputError,
    externalClassNames,
    hideInput,
    handleFocus,
    removeBorder,
  }: EmailOnlyFormProps = useContext(FormContext);

  const iTagProps = {
    type: "sign_up_cta",
    text: "Start for free",
    location: setRouteName(),
  };

  const SubmitBtn = {
    withArrow: (
      <BtnWithArrow
        variant={buttonStyle}
        buttonText={buttonText}
        spinnerVariant={spinnerColor}
        loading={isLoading}
        data-click-type={iTagProps.type}
        data-click-text={iTagProps.text}
        data-click-location={iTagProps.location}
      />
    ),
    withSpinner: (
      <Button
        buttonText={buttonText}
        variant={buttonStyle}
        loading={isLoading}
        data-click-type={iTagProps.type}
        data-click-text={iTagProps.text}
        data-click-location={iTagProps.location}
        data-testid="/button:buttonWithSpinner/"
      />
    ),
  };

  return (
    <form
      data-testid="email-only-form"
      onSubmit={handleSubmit}
      className={`${emailOnlyStyles.emailOnlyFormContainer} ${externalClassNames}`}
    >
      {!hideInput ? (
        <InputField
          inputError={inputError}
          data-testid="modal-form-input"
          value={email}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type="text"
          removeBorder={removeBorder}
        />
      ) : (
        ""
      )}

      {withArrow ? SubmitBtn["withArrow"] : SubmitBtn["withSpinner"]}
    </form>
  );
};

export default EmailOnlyForm;
