import type { GatsbyBrowser } from "gatsby";

import removeScrollOnRouteChange from "../removeScrollOnRouteChange";

const handleOnPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({
  location,
}) => {
  removeScrollOnRouteChange({ location });
};

export default handleOnPreRouteUpdate;
