import React from "react";

/*LINK https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/ */

type SvgType =
  | "arrow-left"
  | "arrow-right"
  | "hi-trust-certified-icon"
  | "hi-trust-certified"
  | "hipaa"
  | "pci-compliance"
  | "sp-logo-blue"
  | "sp-logo-butterfly";

type SvgPathType = "main-icons";

type SvgDefsType = {
  className?: string;
  svg: SvgType;
  svgPath: SvgPathType;
  [key: string]: any;
};

const SvgDefs = ({ svg, className, svgPath, ...rest }: SvgDefsType) => {
  return (
    <svg className={className} {...rest}>
      <use xlinkHref={`/icons/${svgPath}.svg#${svg}`}></use>
    </svg>
  );
};

export default SvgDefs;
