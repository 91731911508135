import React from "react";
import CloseIcon from "../../../components/SVGIcons/CloseIcon/CloseIcon";
import styles from "./AlertMessage.module.scss";

interface IAlertMessage {
  message: string;
  onClose: () => void;
  [key: string]: any;
}
const AlertMessage = ({ message, onClose, ...rest }: IAlertMessage) => {
  return (
    <div
      data-testid="div:AlertMessage"
      {...rest}
      className={styles.emailCaptureErrorMessage}
    >
      {message}
      <CloseIcon onClick={onClose} />
    </div>
  );
};

export default AlertMessage;
