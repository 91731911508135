import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Cookie } from "@utils/Cookie";
import { fireMxpEvent } from "@utils/mixpanelEvents/mixpanelEvents";
import Dropdown from "../Dropdown";
import SECURE_SIMPLEPRACTICE_SIGNIN from "@constants/secureSpSignIn";

import stylesDefault from "./ClientPractitionerDropdownMenu.module.scss";
import stylesRebrand from "./ClientPractitionerDropdownMenuRebrand.module.scss";

import { useDropDown } from "../Dropdown/Dropdown.provider";
import {
  ClientPractitionerType,
  clientPractitionerLink,
} from "@utils/segment/clientPractitionerLink";

/* TODO
 * Remove ClientPractitionerMenu in /components/ClientPractitionerMenu"
 */

export type CPDropdownMenuType = {
  additionalClickEvents?: () => void;
  className?: string;
  clientIntent: "sign_up" | "sign_in";
  signUpUrl?: string;
  withBackDrop?: boolean;
  align?: "left" | "center" | "right";
  preventRedirect?: boolean;
  isRebrand?: boolean;
};

const ClientPractitionerDropdownMenu: React.FC<CPDropdownMenuType> = ({
  additionalClickEvents,
  className,
  clientIntent,
  signUpUrl,
  withBackDrop,
  align = "right",
  preventRedirect,
  isRebrand,
}) => {
  const styles = isRebrand ? stylesRebrand : stylesDefault;
  const { onDropdownClose } = useDropDown();
  const practitionerPaths = {
    sign_in: {
      path: SECURE_SIMPLEPRACTICE_SIGNIN,
      target: "_self",
    },
    sign_up: {
      path: signUpUrl || "/sign-up/",
      target: undefined,
    },
  } as const;

  const practitionerPath = practitionerPaths[clientIntent].path;

  const handleMxpEvent = (
    e: React.MouseEvent<HTMLAnchorElement>,
    customTarget?: string
  ) => {
    const target = e.currentTarget.getAttribute("href");
    const text = e.currentTarget.querySelector(
      'p[class*="menuItemHeadline"]'
    )?.textContent;

    fireMxpEvent("client/practitioner differentiation link clicked", {
      target: target || customTarget,
      text,
      origin: window.location.origin,
    });
  };

  const handleClickProvider = (e: React.MouseEvent<HTMLElement>) => {
    Cookie.setCookie("CLIENT_INTENT", clientIntent, 365);
    handleMxpEvent(e, practitionerPath);

    clientPractitionerLink(
      ClientPractitionerType.PRACTITIONER,
      practitionerPath
    );

    if (additionalClickEvents) {
      e.preventDefault();
      additionalClickEvents();
    }

    if (!preventRedirect) {
      return (window.location.href = practitionerPath);
    }
  };

  const handleClickClient = (e: React.MouseEvent<HTMLAnchorElement>) => {
    Cookie.setCookie("CLIENT_INTENT", clientIntent, 365);
    handleMxpEvent(e);

    clientPractitionerLink(ClientPractitionerType.CLIENT, "/c/client-interim/");
  };

  return (
    <Dropdown.DropdownMenu
      align={align}
      className={`${styles.dropdownMenu} ${
        withBackDrop && styles.backdrop
      } ${className}`}
    >
      <div className={styles.dropdownMenuContent}>
        <p className={styles.headline}>I{`'`}m a</p>

        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <button
              onClick={handleClickProvider}
              className={`${styles.menuItemLink} ${styles.linkButton}`}
              data-testid="practitioner-link"
            >
              <StaticImage
                alt="menu img avatar"
                className={styles.menuItemImage}
                data-testid="practitioner-image"
                formats={["webp", "auto"]}
                src="./menuAssets/avatar-1.png"
              />

              <div>
                <p className={styles.menuItemHeadline}>Practitioner</p>
                <p className={styles.menuItemSubcopy}>I provide care</p>
              </div>
            </button>
          </li>

          <li className={styles.menuItem}>
            <Link
              to="/c/client-interim/"
              className={styles.menuItemLink}
              onClick={handleClickClient}
              data-testid="client-link"
            >
              <StaticImage
                alt="menu img avatar"
                className={styles.menuItemImage}
                data-testid="client-image"
                formats={["webp", "auto"]}
                src="./menuAssets/avatar-2.png"
              />

              <div>
                <p
                  className={styles.menuItemHeadline}
                  data-testid="client-headline"
                >
                  Client
                </p>
                <p
                  className={styles.menuItemSubcopy}
                  data-testid="client-subcopy"
                >
                  I receive care
                </p>
              </div>
            </Link>
          </li>

          <li>
            <button className={styles.cancelBtn} onClick={onDropdownClose}>
              cancel
            </button>
          </li>
        </ul>
      </div>
    </Dropdown.DropdownMenu>
  );
};

export default ClientPractitionerDropdownMenu;
