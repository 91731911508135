const isBrowser = typeof window !== "undefined";

/**
 * getLocalStorageItem.
 * @summary Get saved data from localStorage.
 */
export const getLocalStorageItem = (key: string): any => {
  if (!key || !isBrowser) return null;

  return window.localStorage.getItem(key);
};

/**
 * setLocalStorageItem.
 * @summary Save data to localStorage.
 */
export const setLocalStorageItem = (key: string, val: string) => {
  if (!key || !val || !isBrowser) return null;

  window.localStorage.setItem(key, val);
};

/**
 * removeLocalStorageItem.
 * @summary Remove saved data from localStorage.
 */
export const removeLocalStorageItem = (key: string) => {
  if (!key || !isBrowser) return null;

  window.localStorage.removeItem(key);
};

/**
 * clearLocalStorage.
 * @summary Remove all saved data from localStorage.
 */
export const clearLocalStorage = () => {
  if (!isBrowser) return null;

  window.localStorage.clear();
};
