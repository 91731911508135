import React from "react";

/* Internal modules */
import { setModalSeen } from "../../../utils/Cookie";
import useModalExitStore from "../../../sp-state/modals/useModalExitStore";
import useModalFormSuccessMsgStore from "../../../sp-state/modals/useModalFormSuccessMsgStore";
import useModalsTriggerStore from "../../../sp-state/modals/useModalsTriggerStore";
import useModalVisitorCookieStore from "../../../sp-state/modals/useModalVisitorCookieStore";

import styles from "./BtnCloseEmailCaptureModal.module.scss";
import { modalBannerDismissed } from "@utils/segment/ModalBanner";

type BtnCloseEmailModalWrapperType = {
  isClose: boolean;
  children?: React.ReactNode;
  name: string;
};

const BtnCloseEmailModalWrapper: React.FC<BtnCloseEmailModalWrapperType> = ({
  children,
  isClose,
  name,
}) => {
  const { resetModalTrigger } = useModalsTriggerStore(state => state);
  const { modalVisitorCookie, resetModalVisitorCookie } =
    useModalVisitorCookieStore(state => state);
  const { modalExit, resetModalExit } = useModalExitStore(state => state);
  const { modalFormSuccessMsg, resetModalFormSuccessMsg } =
    useModalFormSuccessMsgStore(state => state);

  const handleClose = () => {
    modalBannerDismissed(name);

    if (modalVisitorCookie) {
      setModalSeen(modalVisitorCookie, resetModalVisitorCookie);
    }

    if (modalExit) {
      document.removeEventListener("mouseleave", modalExit.handleMouseLeave);
      resetModalExit();
    }

    if (modalFormSuccessMsg) resetModalFormSuccessMsg();

    resetModalTrigger();
  };

  const buttonCloseClass = isClose ? styles.close__btn : "";

  return (
    <div
      data-testid="div:BtnCloseEmailModalWrapper"
      className={buttonCloseClass}
      onClick={handleClose}
    >
      {children}
    </div>
  );
};

export default BtnCloseEmailModalWrapper;
