import React from "react";
import { Link } from "gatsby";

import { ButtonVariantType } from "@types";
import SvgDefs from "../../shared/SvgDefs";

import styles from "./Button.module.scss";

export type ButtonType = {
  buttonLink?: string;
  buttonText: string;
  buttonType?: "link" | "default";
  loading?: boolean;
  variant?: ButtonVariantType;
  withArrow?: boolean;
  [key: string]: any;
};

export type BtnOptionsType = {
  children: React.ReactNode;
  className: string;
};

const Button: React.FC<ButtonType> = ({
  children,
  variant = "primary-red",
  buttonText,
  buttonLink = null,
  buttonType = "default",
  withArrow,
  loading,
  ...rest
}) => {
  const variantOpts = {
    "primary-red": styles.primaryRed,
    "primary-blue": styles.primaryBlue,
    "primary-white": styles.primaryWhite,
    "hollow-red": `${styles.btnHollow} ${styles.btnHollowRed}`,
    "hollow-blue": `${styles.btnHollow} ${styles.btnHollowBlue}`,
  };

  const buttonClasses = `${styles.btn} ${variant && variantOpts[variant]} ${
    withArrow && styles.withArrow
  } ${loading && styles.loading}`;

  const textStyles = `${styles.btnText}`;

  const btnTypeOptions = {
    default: ({ children, className, ...rest }: BtnOptionsType) => (
      <button className={className} {...rest}>
        {children}
      </button>
    ),
    link: ({ children, className, ...rest }: BtnOptionsType) => (
      <Link to={buttonLink} role="button" {...rest} className={className}>
        {children}
      </Link>
    ),
  };

  const Btn = btnTypeOptions[buttonType];
  const btnContent = loading ? (
    <span className={styles.btnSpinner} data-testid="button:spinner"></span>
  ) : (
    <span className={textStyles} data-testid="button:text">
      {buttonText}
      {withArrow && (
        <SvgDefs
          data-testid="button:icon"
          className={styles.btnIcon}
          svgPath="main-icons"
          svg="arrow-right"
        />
      )}
    </span>
  );

  return (
    <Btn data-testid="button:Button" className={buttonClasses} {...rest}>
      {btnContent}
    </Btn>
  );
};

export default Button;
