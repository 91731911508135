export const isFloat = (x: number) => {
  return !isNaN(x) && x % 1 !== 0;
};

export const isIFrame = (
  input: HTMLElement | null
): input is HTMLIFrameElement => input !== null && input.tagName === "IFRAME";

export const isValidEmail = (email: string = "") => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const isValidSpURL = (url: string) => {
  if (!url) return false;

  const isValidUrl =
    url &&
    url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    ) &&
    url.includes("simplepractice.com");

  if (!isValidUrl) return false;

  const spURL = new URL(url);

  if (spURL.hostname === window?.location.hostname) return false;

  return true;
};

export const isAppleFamilyDevices = () => {
  const appleFamily = [
    "iPhone",
    "iPad",
    "iPod",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad Simulator",
    "Macintosh",
    "MacIntel",
    "MacPPC",
    "Mac68K",
  ];

  return (
    typeof navigator !== "undefined" && appleFamily.includes(navigator.platform)
  );
};

export const isAttemptExit = (e: MouseEvent) =>
  e?.clientY <= 0 ||
  e?.clientX <= 0 ||
  e?.clientX >= window.innerWidth ||
  e?.clientY >= window.innerHeight;
