import React, { ReactNode } from "react";

import { DropdownBtn } from "./DropdownBtn";
import { DropdownMenu } from "./DropdownMenu";
import DropdownProvider from "./Dropdown.provider";

import styles from "./Dropdown.module.scss";

type DropdownType = {
  children: ReactNode;
  className?: string;
};

const Dropdown = ({ children, className }: DropdownType) => (
  <DropdownProvider>
    <div className={`${styles.dropdownWrapper} ${className}`}>{children}</div>
  </DropdownProvider>
);

Dropdown.Button = DropdownBtn;
Dropdown.DropdownMenu = DropdownMenu;

export default Dropdown;
