import React, { useEffect } from "react";

import getImageSrcData from "../../../utils/getImageSrcData";

import useModalFormSuccessMsgStore from "../../../sp-state/modals/useModalFormSuccessMsgStore";
import useModalsTriggerStore from "../../../sp-state/modals/useModalsTriggerStore";

/* types */
import { ModalType } from "@types";

/* Components */
import ModalSuccessMsg from "../ModalSuccessMsg";
import ModalEmailCaptureContent from "../ModalEmailCaptureContent";
import BtnCloseEmailModalWrapper from "../BtnCloseEmailModalWrapper";

/* Styles */
import styles from "./Modal.module.scss";
import { modalBannerViewed } from "@utils/segment/ModalBanner";

type ModalProps = {
  trigger: string | null;
  modal: ModalType;
};

let timeouts: ReturnType<typeof setTimeout>;
const Modal: React.FC<ModalProps> = ({ trigger, modal }) => {
  const { modalTrigger } = useModalsTriggerStore(state => state);
  const { modalFormSuccessMsg } = useModalFormSuccessMsgStore(state => state);
  const isPollenDigitalMagazine =
    modalTrigger === "pollen-dm-gated" ? true : false;

  const modalBodyStyles = isPollenDigitalMagazine
    ? styles.modalPollenDMBody
    : styles.modalBody;

  const image = getImageSrcData(modal?.image) as string;
  const successModalTitle = "Thank you for signing up!";

  if (trigger) {
    // modal get invoked multiple times so setTimeout with clearTimeout is required
    if (timeouts) {
      clearTimeout(timeouts);
    }
    timeouts = setTimeout(() => {
      if (modalFormSuccessMsg || modal?.header) {
        window.dataLayer?.push({
          event: "modal_popup",
          modal_name: modalFormSuccessMsg ? successModalTitle : modal.header,
        });
      }
    }, 200);
  }

  useEffect(() => {
    if (trigger) {
      modalBannerViewed(modal?.modalName);
    }
  }, [modal?.modalName, trigger]);

  return (
    <div
      data-testid="modal"
      className={`${styles.modalContainer} ${
        trigger ? styles.modalContainerActive : ""
      }`}
    >
      <div
        className={`${styles.modalContent}  ${
          trigger ? styles.modalContentActive : ""
        }`}
      >
        {modal?.image && (
          <img
            data-testid="modal-image"
            alt={modal.altText}
            src={modal.image}
          />
        )}
        <div className={modalBodyStyles}>
          {!modalFormSuccessMsg ? (
            <ModalEmailCaptureContent
              ctaText={modal?.ctaText}
              ctaLink={modal?.ctaLink}
              header={modal?.header}
              subheaderCopy={modal?.subHeaderCopy}
              ctaId={modal?.ctaId}
              hideInput={modal?.hideInput}
              excludeVisitorCookie={
                modal?.conditionsRepeater[0].excludeVisitorCookie!
              }
              subcopy={modal?.subcopy}
              modalName={modal?.modalName}
            />
          ) : (
            <ModalSuccessMsg
              title={successModalTitle}
              subcopy={modal?.subcopy}
            />
          )}

          <BtnCloseEmailModalWrapper isClose name={modal?.modalName}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#747474"
              width="24"
              height="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
            </svg>
          </BtnCloseEmailModalWrapper>
        </div>
      </div>
    </div>
  );
};

export default Modal;
