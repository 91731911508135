import React from "react";
import EmailCaptureForm from "../../Forms/EmailCaptureForm";
import styles from "../EmailCaptureModal/EmailCaptureModal.module.scss";

type Props = {
  header: string;
  subheaderCopy: string;
  ctaId: string;
  ctaLink: string | null;
  ctaText: string;
  hideInput: boolean | null;
  excludeVisitorCookie: string;
  subcopy: string;
  modalName: string;
};

const ModalEmailCaptureContent: React.FC<Props> = ({
  header,
  subheaderCopy,
  subcopy,
  ctaId,
  ctaLink,
  ctaText,
  hideInput,
  excludeVisitorCookie,
  modalName,
}) => {
  return (
    <>
      <h5 data-testid="modal-header" className={styles.modalBodyTitle}>
        {header}
      </h5>

      <p data-testid="modal-subheader" className={styles.modalBodySubtitle}>
        {subheaderCopy}
      </p>

      <EmailCaptureForm
        ctaId={ctaId}
        ctaText={ctaText}
        ctaLink={ctaLink}
        hideInput={hideInput}
        visitorCookies={excludeVisitorCookie}
        modalName={modalName}
      />

      <p data-testid="modal-subcopy" className={styles.emailSubcopy}>
        {subcopy}
      </p>
    </>
  );
};

export default ModalEmailCaptureContent;
