import React from "react";
import { SpinnerVariantType } from "../../../assets/Types/ButtonsAndSpinners.type";

import styles from "./Spinner.module.scss";

type SpinnerProps = {
  variant?: SpinnerVariantType;
};

const Spinner: React.FC<SpinnerProps> = ({ variant }) => {
  const options = {
    red: styles.red,
    primary: styles.primary,
  };

  const spinnerClasses = `${styles.spinner} ${
    options[variant] || !variant ? options[variant] : options["primary"]
  }`;

  return <div data-testid="div:Spinner" className={spinnerClasses}></div>;
};

export default Spinner;
