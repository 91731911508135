import React from "react";
import ErrorIcon from "../../../SVGIcons/ErrorIcon";

import styles from "./InputField.module.scss";

type InputFieldProps = {
  inputError?: boolean;
  customErrorIcon?: boolean;
  removeBorder?: boolean;
  [key: string]: any;
};

const InputField: React.FC<InputFieldProps> = ({
  inputError,
  customErrorIcon,
  removeBorder = false,
  ...rest
}) => {
  return (
    <div className={styles.inputField__wrapper}>
      <input
        {...rest}
        className={`${styles.inputField} ${
          (inputError || customErrorIcon) && !removeBorder
            ? styles.inputField__error
            : ""
        }`}
      />
      {customErrorIcon && <span className={styles.errorIcon}></span>}
      {inputError && <ErrorIcon className={styles.inputField__error__icon} />}
    </div>
  );
};

export default InputField;
