import React from "react";

import { useDropDown } from "./Dropdown.provider";

import styles from "./Dropdown.module.scss";

type DropdownItemsType = {
  children: React.ReactNode;
  className?: string;
  align?: "left" | "center" | "right";
};

export const DropdownMenu: React.FC<DropdownItemsType> = ({
  children,
  align = "left",
  className,
}) => {
  const { showMenu } = useDropDown();

  return (
    <>
      {showMenu && (
        <div className={`${styles.dropdownMenu} ${styles[align]} ${className}`}>
          {children}
        </div>
      )}
    </>
  );
};
