import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEventHandler,
  forwardRef,
  useEffect,
} from "react";
import { useDropDown } from "./Dropdown.provider";

import styles from "./Dropdown.module.scss";
import useWindowSize from "@hooks/useWindowSize";

type BtnDefaultAttrs = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type DropdownBtnType = {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  analyticsClickEvents?: MouseEventHandler<HTMLButtonElement>;
  isNavItem?: boolean /* this is only for main nav items */;
} & BtnDefaultAttrs;

export const DropdownBtn = forwardRef<HTMLButtonElement, DropdownBtnType>(
  (
    { className, onClick, analyticsClickEvents, children, isNavItem, ...rest },
    ref
  ) => {
    const { onDropdownClick, onDropdownClose, showMenu } = useDropDown();
    const { width: windowWidth } = useWindowSize();
    const isMobile = windowWidth <= 768;

    useEffect(() => {
      /* NOTE if we decide that for better ux in the main nav we want to close the menu on outside click, remove this statement and trace back isNavItem prop */
      if (isNavItem && isMobile) {
        return;
      }

      const handleOutsideClick = (e: MouseEvent) => {
        if (ref && typeof ref === "object" && e.target !== ref?.current) {
          onDropdownClose();
          return;
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => document.removeEventListener("click", handleOutsideClick);
    }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (analyticsClickEvents && !showMenu) {
        analyticsClickEvents(e);
      }

      onDropdownClick();
      (e.target as HTMLElement).focus();
    };

    return (
      <button
        onClick={handleClick}
        className={`${styles.dropdownBtn} ${className}`}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

DropdownBtn.displayName = "DropdownBtn";
