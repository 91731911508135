import {
  extoleStart,
  googleTagManager,
  mixPanelScript,
  partnerStack,
  segmentScript,
} from "../../../node-utils/third-party-exports";
import { Script } from "gatsby";
import * as React from "react";

const PageScripts: React.FunctionComponent = () => {
  return (
    <>
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: googleTagManager }}
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: segmentScript }}
      />
      <Script dangerouslySetInnerHTML={{ __html: mixPanelScript }} />
      <Script
        strategy={"idle"}
        async
        src="https://simplepractice.extole.io/core.js"
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: partnerStack }}
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: extoleStart }}
      />
    </>
  );
};

export default PageScripts;
