import * as React from "react";
import { Link } from "gatsby";

import { mxpPrimaryCtaClicked } from "../../../utils/mixpanelEvents/mixpanelEvents";

import styles from "./PrimaryButton.module.scss";
import { MxpCtaClickedArgsType } from "@types";

type ButtonProps = {
  buttonText?: string;
  onClickHandler?: (e: React.MouseEvent) => void;
  buttonLink?: string;
  className?: string;
  externalClassName?: string;
  children?: React.ReactNode;
  target?: "_blank" | "_parent" | "_top" | "_self";
  noMxp?: boolean;
  [key: string]: any;
};

const PrimaryButton: React.FC<ButtonProps> = ({
  buttonText = "",
  onClickHandler,
  buttonLink,
  className = "",
  externalClassName = "",
  children,
  target,
  noMxp = false,
  ...rest
}: ButtonProps) => {
  const classNameOpts = {
    blue: styles.blue,
    white: styles.white,
    full: styles.full,
    "hollow--red": styles.hollow__red,
  };

  const handleBtnClick = (e: any) => {
    const dataPricingPlan = e.currentTarget.dataset[
      "pricingPlan"
    ] as MxpCtaClickedArgsType["pricingPlan"];
    const data = dataPricingPlan
      ? {
          pricingPlan: dataPricingPlan,
        }
      : null;
    if (!noMxp) mxpPrimaryCtaClicked(e.target.href, e.target.innerText, data);
    onClickHandler && onClickHandler(e);
  };

  return buttonLink ? (
    <Link
      data-testid="A:PrimaryButton"
      onClick={handleBtnClick}
      className={`${styles.primaryTrialBtn} ${classNameOpts[className]} ${externalClassName}`}
      to={buttonLink}
      target={target}
      {...rest}
    >
      {buttonText}
      {children}
    </Link>
  ) : (
    <a
      data-testid="A:PrimaryButton"
      onClick={handleBtnClick}
      className={`${styles.primaryTrialBtn} ${classNameOpts[className]} ${externalClassName}`}
      href=" "
    >
      {buttonText}
      {children}
    </a>
  );
};

export default PrimaryButton;
