import * as React from "react";

type RightArrowProp = {
  cls?: string;
  viewBox?: string;
};

const RightArrow: React.FC<RightArrowProp> = ({ cls, viewBox }) => (
  <svg
    className={cls}
    xmlns="http://www.w3.org/2000/svg"
    fill="#1371C8"
    width="24"
    height="24"
    viewBox={viewBox}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 000-1.41l-6.58-6.6a.996.996 0 10-1.41 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z" />
  </svg>
);

export default RightArrow;
