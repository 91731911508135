import { create } from "zustand";

const initialState = {
  modalVisitorCookie: null,
};

const useModalVisitorCookieStore = create<Modals.UseModalVisitorCookie>(
  set => ({
    ...initialState,
    resetModalVisitorCookie: () => set(initialState),
    setModalVisitorCookie: val => set({ modalVisitorCookie: val }),
  })
);

export default useModalVisitorCookieStore;
