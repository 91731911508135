const isBrowser = typeof window !== "undefined";

/**
 * setSessionStorageItem.
 * @summary Save data to sessionStorage.
 */
export const setSessionStorageItem = (key: string, value: string): void => {
  if (isBrowser) window.sessionStorage.setItem(key, value);
};

/**
 * getSessionStorageItem.
 * @summary Get saved data from sessionStorage.
 */
export const getSessionStorageItem = (key: string): string | null => {
  if (!isBrowser) return null;

  return window.sessionStorage.getItem(key) || "";
};

/**
 * clearSessionStorage.
 * @summary Remove all saved data from sessionStorage.
 */
export const clearSessionStorage = (): void => {
  if (isBrowser) window.sessionStorage.clear();
};

/**
 * removeSessionStorageItem.
 * @summary Remove saved data from sessionStorage.
 */
export const removeSessionStorageItem = (key: string): void => {
  if (isBrowser) window.sessionStorage.removeItem(key);
};
