import React, { useEffect, useState } from "react";
import type { PageProps } from "gatsby";
import { formtModalsToObj } from "../../utils/formatModalsToObject";
import { getModalTrigger } from "../../utils/getModalTrigger";
import { checkVisitorCookie } from "../../utils/checkVisitorCookie";
import { getPageCount } from "../../utils/getPageCount";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { isAttemptExit } from "../../utils/validations";
import { Cookie } from "../../utils/Cookie";
import handlePromocodeInUrl from "../../utils/handlePromocodeInUrl";
import Modal from "../../components/Modals/Modal";
import GenericFormSuccessBanner from "../../components/Banners/GenericFormSuccessBanner";
import useWindowSize from "../../hooks/useWindowSize";
import useFormSuccessBannerStore from "../../sp-state/useFormSuccessBanner";

/* zustand imports */
import useModalsStore from "../../sp-state/modals/useModalsStore";
import useModalsTriggerStore from "../../sp-state/modals/useModalsTriggerStore";
import useModalVisitorCookieStore from "../../sp-state/modals/useModalVisitorCookieStore";
import useModalExitStore from "../../sp-state/modals/useModalExitStore";
import builder from "@builder.io/react";
import { ModalType } from "@types";
import LuminelloModal from "@components/Modals/LuminelloModal";
import { StringParam, useQueryParam } from "use-query-params";

const ModalContainer: React.FC<PageProps> = ({ children, location }) => {
  /* zustand state */
  const { setModals, getModal } = useModalsStore(state => state);
  const {
    modalTrigger,
    setValidModalTriggers,
    setModalTrigger,
    resetModalTrigger,
  } = useModalsTriggerStore(state => state);
  const { showSuccessBanner, resetFormSuccessBanner } =
    useFormSuccessBannerStore(state => state);
  const [modalWithPageCount, setModal] = useState(getModal(modalTrigger));
  const { setModalVisitorCookie } = useModalVisitorCookieStore(state => state);
  const { setModalExit } = useModalExitStore(state => state);
  const modal = getModal(modalTrigger);

  const [isLuminelloModalOpen, setIsLuminelloModalOpen] = useState(false);
  const [modalQPKey] = useQueryParam("modal", StringParam);
  const [luminelloData, setLuminelloData] =
    useState<Queries.builder_LuminelloModalData>();

  const promocode = handlePromocodeInUrl(location);
  const windowSize = useWindowSize();

  useEffect(() => {
    let handleMouseLeave = (e: MouseEvent) => {};
    let modalTimeout: ReturnType<typeof setTimeout>;

    builder.getAll("email-capture-option").then(res => {
      const modalsData = res.map(item => item?.data) as ModalType[];
      setModals(modalsData);
      const modalsMapData = formtModalsToObj(modalsData);
      setValidModalTriggers(Object.keys(modalsMapData));

      let modalData = null;

      if (promocode) {
        Cookie.deleteCookieFromBaseDomain("PROMOCODE");
        Cookie.setCookie("PROMOCODE", promocode, 365);
      }

      getModalTrigger(modalsData, location.pathname, "click", data => {
        if (data) {
          return (modalData = data);
        }
      });

      if (modalData) {
        const {
          modalTrigger,
          timeToWait,
          visitorCookie,
          viewedPages,
          showType,
          onExitAttemp,
        } = modalData;

        const cookie = getLocalStorageItem(visitorCookie);
        const isValidCookie = checkVisitorCookie(
          visitorCookie,
          setModalVisitorCookie
        );
        const pageCount = getPageCount();

        if (
          (!cookie || !isValidCookie) &&
          pageCount >= viewedPages &&
          windowSize.width >= 1023
        ) {
          if (onExitAttemp || showType === "exit") {
            handleMouseLeave = (e: MouseEvent) => {
              if (isAttemptExit(e) && !getLocalStorageItem(visitorCookie)) {
                setModal(getModal(modalTrigger));
                return setModalTrigger(modalTrigger);
              }
            };
          }

          document.addEventListener("mouseleave", handleMouseLeave);
          setModalExit(handleMouseLeave);

          modalTimeout = setTimeout(() => {
            if (!getLocalStorageItem(visitorCookie)) {
              setModal(getModal(modalTrigger));
              setModalTrigger(modalTrigger);
            }
          }, timeToWait);
        }
      }
    });

    return () => {
      clearTimeout(modalTimeout);
      document.removeEventListener("mouseleave", handleMouseLeave);
      resetModalTrigger();
      resetFormSuccessBanner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    builder.getAll("url-triggered-modal").then(modals => {
      setIsLuminelloModalOpen(false);
      modals.forEach(modalData => {
        const { data } = modalData;
        const luminelloCookie = Cookie.getCookie(data?.cookieName);

        if (
          !luminelloCookie &&
          data?.urlParameter.includes(modalQPKey) &&
          !isLuminelloModalOpen
        ) {
          setLuminelloData(data as Queries.builder_LuminelloModalData);
          setIsLuminelloModalOpen(true);
          Cookie.setCookie(data.cookieName, "true", 30);
        }
      });
    });
  }, []);

  const onCloseLuminelloModal = () => {
    setIsLuminelloModalOpen(false);
  };

  return (
    <>
      {showSuccessBanner && (
        <GenericFormSuccessBanner
          location={location.pathname}
          data-testid="form-success-banner"
        />
      )}
      {isLuminelloModalOpen && luminelloData && (
        <LuminelloModal
          modal={luminelloData}
          onCloseModal={onCloseLuminelloModal}
        />
      )}
      {children}
      <Modal modal={modal! || modalWithPageCount} trigger={modalTrigger} />
    </>
  );
};

export default ModalContainer;
