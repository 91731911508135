import React from "react";

import { EmailOnlyFormProps } from "../EmailOnlyForm/Email.type";
import { FormContext } from "../../../contexts/FormOnlyEmail.context";
import EmailOnlyForm from "../EmailOnlyForm";
import EmailOnlyFormWithDropdown from "../EmailOnlyFormWithDropdown";

type EmailOnlyFormProviderProps = {
  ctxInitialVal: EmailOnlyFormProps;
  shouldHaveExp?: boolean;
  withDropdown?: boolean;
};

const EmailOnlyFormProvider: React.FC<EmailOnlyFormProviderProps> = ({
  ctxInitialVal,
  shouldHaveExp = true,
  withDropdown,
}) => {
  const Form: React.FC = withDropdown
    ? EmailOnlyFormWithDropdown
    : EmailOnlyForm;

  return (
    <FormContext.Provider value={ctxInitialVal}>
      <Form />
    </FormContext.Provider>
  );
};

export default EmailOnlyFormProvider;
