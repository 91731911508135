import { isValidSpURL } from "@utils/validations";

type TSPFetchGet = (url: string) => Promise<any>;
type TSPFetchPost<T = any> = (url: string, data: T) => Promise<Response>;

export default class SPfetch {
  static get: TSPFetchGet = async url => {
    const response = await fetch(url);
    return response.json();
  };

  static post: TSPFetchPost = (url, data) => {
    const isDev = process.env.GATSBY_ENVIRONMENT === "dev";
    const custom_referrer = document?.referrer;

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(isDev &&
          isValidSpURL(custom_referrer) && {
            custom_referrer: custom_referrer.replace(/\/+$/, ""),
          }),
      },
      body: JSON.stringify(data),
    });
  };
}
