export enum ClientPractitionerType {
  CLIENT = "Client",
  PRACTITIONER = "Practitioner",
}

export function clientPractitionerLink(
  type_selected: ClientPractitionerType,
  target: string
): void {
  if (typeof window !== "undefined" && window?.analytics?.track) {
    window.analytics.track("Marketing CTA User Differentiation Clicked", {
      type_selected,
      url: window.location.pathname,
      source_application: "Marketing",
      object: "Market CTA User Differentiation",
      action: "Clicked",
      target,
      initiator: "User",
    });
  }
}
