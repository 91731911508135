import { create } from "zustand";

type UseFormSuccessBannerStoreType = {
  showSuccessBanner: boolean;
  setFormSuccessBanner: (val: boolean) => void;
  resetFormSuccessBanner: () => void;
};

const initialState = false;

const useFormSuccessBannerStore = create<UseFormSuccessBannerStoreType>(
  set => ({
    showSuccessBanner: initialState,
    setFormSuccessBanner: val => set(_ => ({ showSuccessBanner: val })),
    resetFormSuccessBanner: () =>
      set(_ => ({ showSuccessBanner: initialState })),
  })
);

export default useFormSuccessBannerStore;
