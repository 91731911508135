import {
  ModalsMapType,
  ModalType,
} from "../assets/Types/AcfGroupTypes/EmailCaptureModal";

/**
 * formtModalsToObj
 * @param {Array} modalArr - expected to be an array
 * @return {Object} returns the modals array converted into an object.
 */

export const formtModalsToObj = (modalArr: ModalType[]): ModalsMapType => {
  return modalArr.reduce((acc, modal) => {
    acc[modal.ctaId] = {
      ...modal,
    };

    return acc;
  }, {});
};
