type LeadCaptureType = {
  text?: string;
  url?: string;
  email?: string;
  resource?: string | null;
};

export function leadCapture({
  text,
  url,
  email,
  resource,
}: LeadCaptureType): void {
  if (typeof window !== "undefined" && window?.analytics?.track) {
    window.analytics.track("Lead Captured", {
      lead_type: "email",
      initiator: "User",
      object: "Lead",
      action: "Captured",
      source_application: "Marketing",
      text,
      url: url || window.location.pathname,
      email,
      resource,
    });
  }
}
