import { getLocalStorageItem, removeLocalStorageItem } from "./LocalStorage";

export const checkVisitorCookie = (
  target: string,
  setStateFunc: (visitorCookie: string) => void
): boolean => {
  const currentCookie = getLocalStorageItem(target);

  if (!currentCookie) {
    setStateFunc(target);
    return false;
  }

  if (Date.now() - Number(currentCookie) > 24 * 60 * 60 * 1000) {
    removeLocalStorageItem(target);
    setStateFunc(target);
    return false;
  }

  return true;
};
