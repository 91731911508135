const removeScrollOnRouteChange = ({ location }) => {
  const shouldRemoveScroll =
    location.pathname !== window.location.pathname &&
    document.querySelector("html").hasAttribute("style");
  if (shouldRemoveScroll) {
    document.querySelector("html").removeAttribute("style");
  }
};

export default removeScrollOnRouteChange;
