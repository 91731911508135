import { FireMxpEventType, MxpPrimaryCtaClickedType } from "@types";
import { Cookie } from "../Cookie";
import { isValidEmail } from "../validations";

export const fireMxpEvent: FireMxpEventType = (label, data) => {
  if (!label) throw new Error("Missing parameter at position one");

  let intervalCounter = 0;

  const mxpInterval = setInterval(() => {
    intervalCounter++;
    if (
      typeof window !== "undefined" &&
      typeof window.trackMixpanelEvent === "function"
    ) {
      window.trackMixpanelEvent(label, data!);
      clearInterval(mxpInterval);
    }
    if (intervalCounter >= 20) {
      clearInterval(mxpInterval);
      throw new Error("Mixpanel library timed out");
    }
  }, 100);
};

export const mxpPageViewed = () => {
  const currentUrl = new URL(window.location.href);
  const utmSrc = {
    utm_medium: currentUrl.searchParams.get("utm_source"),
  };
  const utmMedium = {
    utm_source: currentUrl.searchParams.get("utm_medium"),
  };
  const mpObj = {
    page: window.location.pathname,
    $current_url: window.location.href,
    "New User": Cookie.getCookie("sp_last_access") ? "false" : "true",
    ...(currentUrl.searchParams.has("utm_source") && utmSrc),
    ...(currentUrl.searchParams.has("utm_medium") && utmMedium),
  };
  window.trackMixpanelEvent("user: Marketing page viewed", mpObj);
};

export const mxpBlogPostViewed = () => {
  window.trackMixpanelEvent("user: Blog viewed", {
    blog_post: window.location.pathname,
    $current_url: window.location.href,
    promo_code: Cookie.getCookie("PROMOCODE")
      ? Cookie.getCookie("PROMOCODE")
      : "",
    "New User": Cookie.getCookie("sp_last_access") ? "false" : "true",
  });
};

export const mxpPrimaryCtaClicked: MxpPrimaryCtaClickedType = (
  target,
  text,
  options
) => {
  const initialProps = {
    email: "",
    withEmailField: false,
    pricingPlan: null,
  };

  const temp = {
    ...initialProps,
    ...options,
  };

  const $current_url = window.location.href;
  const isEmailField =
    temp.withEmailField && isValidEmail(temp.email) ? true : false;
  const path = window.location.pathname;
  let product: "pollen" | "simplepractice";
  let sp_email_entered = null;

  if (temp.withEmailField) {
    sp_email_entered = isEmailField;
  }

  if (path.includes("blog") || path.includes("pollen")) {
    product = "pollen";
  } else {
    product = "simplepractice";
  }

  fireMxpEvent("user: primary cta clicked", {
    $current_url,
    product,
    target,
    text,
    sp_email_entered,
    sp_pricing_plan: temp.pricingPlan,
  });
};

export const mxpProductAdClicked = (target: string, title: string) => {
  const $current_url = window.location.href;
  const path = window.location.pathname;
  let product;

  if (path.includes("blog") || path.includes("pollen")) {
    product = "pollen";
  } else {
    product = "simplepractice";
  }

  window.trackMixpanelEvent("user: Pollen rotating ad clicked", {
    $current_url,
    product,
    target,
    title,
  });
};

export const mxpPricingCalculatorOpen = () => {
  const $current_url = window.location.href;
  let product = "simplepractice";

  window.trackMixpanelEvent("user: pricing calculator - displayed", {
    $current_url,
    product,
  });
};

export const mxpPricingCalculatorClosed = (timeSpent: number) => {
  const $current_url = window.location.href;
  let product = "simplepractice";

  window.trackMixpanelEvent("user: pricing calculator - closed", {
    $current_url,
    product,
    timeSpent,
  });
};

export const mxpNavClicked = (
  target: string,
  text: string,
  category: string
) => {
  const $current_url = window.location.href;

  window.trackMixpanelEvent("user: marketing nav clicked", {
    $current_url,
    category,
    target,
    text,
  });
};

export const mxpClientInterimCTAClicked = (target: string, text: string) => {
  window.trackMixpanelEvent("user: client interm link clicked", {
    target,
    text,
  });
};

export const mxpAppBadgeClicked = (target: string) => {
  window.trackMixpanelEvent("user: client app download clicked", {
    target,
  });
};

export const mxpSignupFirstView = (cookie: string, consultCall?: boolean) => {
  if (!window.mixpanelTrialSent) {
    window.trackMixpanelEvent("user: Trial sign up page step 1 viewed", {
      "Signup Group": "No Credit Card Upfront",
      "Drift Prospect": false,
      "Session Test Group": Cookie.getCookie(cookie),
      "Sign Up API": true,
      consultcall: consultCall,
    });
  }

  window.mixpanelTrialSent = true;
};

export const mxpEmailCaptured = (email, text) => {
  const origin = window.location.href;
  const path = window.location.pathname;

  let product;
  let title;
  let type;

  if (
    path.includes("resource") ||
    path.includes("insurance") ||
    path.includes("business") ||
    path.includes("private-practice")
  ) {
    title = document.title;
    type = "resources";
  }

  if (path.includes("blog") || path.includes("pollen")) {
    product = "pollen";
  } else {
    product = "simplepractice";
  }

  window.trackMixpanelEvent("email: lead captured", {
    email,
    origin,
    product,
    text,
    title,
    type,
  });
};

export const mxpUserExperiment = (variant: string, experimentId: string) => {
  window.trackMixpanelEvent("user: experiment started", {
    variation: variant,
    experimentId,
  });
};

export const mxpResourceDownload = (
  sp_resource?: string | null,
  sp_last_access?: string | null,
  text?: string
) => {
  window.trackMixpanelEvent("user: resource downloaded", {
    ...(sp_last_access && { sp_last_access: true }),
    ...(sp_resource && { sp_resource: true }),
    title: document?.title,
    text,
    page: window.location.pathname,
  });
};

// Sign Up Mobile
export const mxpStepCompleted = (currentStep: number) => {
  window.trackMixpanelEvent(`user: mobile sign up ${currentStep} completed`, {
    $current_url: window.location.href,
  });
};

export const mxpInputFieldUpdated = (
  error: boolean,
  time: number,
  name: string
) => {
  window.trackMixpanelEvent("user: sign up field updated", {
    $current_url: window.location.href,
    sp_error: error,
    sp_field: name,
    sp_time: time,
  });
};

// Sign Up Desktop
export const mxpContinueOnDesktop = () => {
  window.trackMixpanelEvent("user: continue on desktop - clicked", {
    $current_url: window.location.href,
  });
};

export const mxpContinueOnDesktopEmailEntered = (sp_time: number) => {
  window.trackMixpanelEvent("user: continue on desktop - email entered", {
    $current_url: window.location.href,
    sp_time,
  });
};

export const mxpContinueOnDesktopCompleted = () => {
  window.trackMixpanelEvent("user: continue on desktop - completed", {
    $current_url: window.location.href,
  });
};
