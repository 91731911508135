import React, { ElementRef, useRef } from "react";

import { mxpPrimaryCtaClicked } from "@utils/mixpanelEvents/mixpanelEvents";
import ClientPractitionerDropdownMenu from "../ClientPractitionerDropdownMenu";
import Dropdown from "../Dropdown";
import { marketingCtaClicked } from "@utils/segment/marketingCtaClicked";

import styles from "./DropDownWithArrow.module.scss";

type DropDownWithArrowType = {
  email?: string;
  label: string;
  signUpUrl?: string;
  variant?: keyof typeof variantOpts;
  additionalClickEvents?: () => void;
  preventRedirect?: boolean;
};

const variantOpts = {
  "primary-ocean": styles.primaryOcean,
  "primary-salmon": styles.primarySalmon,
} as const;

const DropDownWithArrow: React.FC<DropDownWithArrowType> = ({
  email,
  label,
  signUpUrl,
  variant = "primary-salmon",
  additionalClickEvents,
  preventRedirect = false,
}) => {
  const buttonRef = useRef<ElementRef<"button">>(null);

  const handleClick = () => {
    const emailIncluded = email && email.length > 0 ? email : undefined;

    mxpPrimaryCtaClicked(null!, label, {
      email,
      withEmailField: email ? true : false,
    });

    marketingCtaClicked({
      emailIncluded,
      text: label,
      url: window.location.pathname,
    });
  };

  return (
    <Dropdown className={styles.container}>
      <Dropdown.Button
        analyticsClickEvents={handleClick}
        className={`${styles.cta} ${variantOpts[variant]}`}
        ref={buttonRef}
      >
        {label}

        <svg className={styles.icon}>
          <use href="/icons/right-arrow.svg#right-arrow"></use>
        </svg>
      </Dropdown.Button>

      <ClientPractitionerDropdownMenu
        className={styles.menu}
        clientIntent="sign_up"
        signUpUrl={signUpUrl}
        withBackDrop
        additionalClickEvents={additionalClickEvents}
        preventRedirect={preventRedirect}
      />
    </Dropdown>
  );
};

export default DropDownWithArrow;
