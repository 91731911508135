import { IterableDataType } from "@types";
import SPfetch from "../services/SPFetch.class";

export const iterableSubmit = (data: IterableDataType) => {
  const endpoint = `${process.env.GATSBY_WS_URL}/api/iterable/userSubmit`;
  data.eventName = data.eventName ? data.eventName : "newUserSignup";

  const UTM_CAMPAIGN = sessionStorage.getItem("utm_campaign");
  const UTM_MEDIUM = sessionStorage.getItem("utm_medium");
  const UTM_SOURCE = sessionStorage.getItem("utm_source");

  if (UTM_CAMPAIGN) data["UTM_CAMPAIGN"] = UTM_CAMPAIGN;
  if (UTM_MEDIUM) data["UTM_MEDIUM"] = UTM_MEDIUM;
  if (UTM_SOURCE) data["UTM_SOURCE"] = UTM_SOURCE;

  return SPfetch.post(endpoint, data);
};
