import { setLocalStorageItem } from "../LocalStorage";

export class Cookie {
  static setCookie(
    cname: string,
    cvalue: string,
    exdays: number,
    isSession = false,
    domain: string | null = null
  ) {
    const date = new Date();
    const cookie = `${cname}=${cvalue};`;
    const cDomain = domain ? "; domain=" + domain : "";

    if (typeof document !== "undefined" && isSession) {
      return (document.cookie = `${cookie} path=/${cDomain}`);
    }

    if (typeof document !== "undefined") {
      date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()};`;
      return (document.cookie = `${cookie} ${expires} path=/${cDomain}`);
    }
  }

  static getCookie(cname) {
    const name = `${cname}=`;
    if (typeof document !== "undefined") {
      const ca = document.cookie.split(";");

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
    }

    return null;
  }

  static deleteCookieFromBaseDomain(name) {
    document.cookie =
      name +
      "=; Path=/; domain=.simplepractice.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  static deleteCookie(name, domain) {
    document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  static checkCookie(cname) {
    const cookie = Cookie.getCookie(cname);
    return !!cookie;
  }
}

export const setModalSeen = (visitorCookie, resetState) => {
  setLocalStorageItem(visitorCookie, JSON.stringify(Date.now()));
  return resetState();
};
