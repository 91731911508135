type CopyOpts = { [key: string]: string };

export const getSuccessBannerCopy = (
  copyOpts?: CopyOpts,
  copyOpt?: string
): string => {
  const defaultMsg = "Thank you for signing up!";

  if (!copyOpts || !copyOpt) return defaultMsg;

  for (const opt in copyOpts) {
    if (copyOpt.includes(opt)) {
      return copyOpts[opt];
    }
  }

  return defaultMsg;
};
