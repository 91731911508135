import { create } from "zustand";

import { getLocalStorageItem } from "../../utils/LocalStorage";

type UseGatedVideoStoreType = {
  locked: boolean;
  setLocked: (val: boolean) => void;
};

const useGatedVideoStore = create<UseGatedVideoStoreType>(set => ({
  locked: !getLocalStorageItem("gated_pass"),
  setLocked: val => set({ locked: val }),
}));

export default useGatedVideoStore;
