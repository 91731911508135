import React from "react";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import BtnCloseEmailModalWrapper from "../BtnCloseEmailModalWrapper";

import styles from "./ModalSuccessMsg.module.scss";

type ModalSuccessMsgProps = {
  title: string;
  subcopy: string;
};

const ModalSuccessMsg: React.FC<ModalSuccessMsgProps> = ({
  title,
  subcopy,
}) => {
  return (
    <section
      data-testid="section:ModalSuccessMsg"
      className={styles.successModalWrapper}
    >
      <h5 className={styles.successModalWrapperHeading}>{title}</h5>

      <BtnCloseEmailModalWrapper isClose={false}>
        <PrimaryButton
          buttonText="Close"
          externalClassName={styles.successModalWrapperBtn}
        />
      </BtnCloseEmailModalWrapper>

      <p
        data-testid="modal-subcopy"
        className={styles.successModalWrapperSubHeading}
      >
        {subcopy}
      </p>
    </section>
  );
};

export default ModalSuccessMsg;
