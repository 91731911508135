import { create } from "zustand";

const initialState = {
  modalTrigger: null,
  validModalTriggers: [],
};

const useModalsTriggerStore = create<Modals.UseModalsTrigger>(set => ({
  ...initialState,
  resetModalTrigger: () => set({ modalTrigger: null }),
  setModalTrigger: val => set({ modalTrigger: val }),
  setValidModalTriggers: triggers => set({ validModalTriggers: triggers }),
}));

export default useModalsTriggerStore;
