import { Cookie } from "./src/utils/Cookie";
import handleOnRouteUpdate from "./src/utils/handleOnRouteUpdate";
import handleOnPreRouteUpdate from "./src/utils/handleOnPreRouteUpdate";
import PageElementWrapper from "./src/components/PageElementWrapper";
import "./src/assets/global-styles/globals.css";

/**
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

if (!Cookie.checkCookie("TESTGROUP_ID")) {
  Cookie.setCookie("TESTGROUP_ID", Math.floor(Math.random() * 10), 365);
}

export const onRouteUpdate = handleOnRouteUpdate;
export const onPreRouteUpdate = handleOnPreRouteUpdate;

export const wrapPageElement = PageElementWrapper;
