import {
  ModalType,
  PageUrlsType,
  UrlPartType,
} from "../../assets/Types/AcfGroupTypes/EmailCaptureModal";

export type UrlsType = UrlPartType[] | PageUrlsType[];

export type ShowType = "click" | "exit" | "inactive";

export type ReturnData = {
  modalTrigger: string;
  timeToWait: number;
  visitorCookie: string;
  viewedPages: number;
  showType: string;
  onExitAttemp: boolean | null;
} | null;

export const urlsContainCurrentUrl = (
  urls: PageUrlsType[],
  key: string,
  currentUrl: string
) => {
  if (!urls) return null;

  return urls.some(url => currentUrl === url[key]);
};

export const filterModals = (modals: ModalType[], showtype: ShowType) =>
  modals.filter(
    modal =>
      modal.conditionsRepeater[0].showType !== showtype &&
      modal.ctaId !== "sitewide-generic-exit"
  );

export const isExcludeOrInclude = (
  excludeOrIncludePages: string,
  target: string
) => excludeOrIncludePages?.toLowerCase() === target.toLowerCase();
