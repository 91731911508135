import React, { ReactNode, createContext, useContext, useState } from "react";

type DropdownCtxType = {
  onDropdownClick: () => void;
  onDropdownClose: () => void;
  showMenu: boolean;
};

type DropdownProviderType = {
  children: ReactNode;
};

const DropdownCtx = createContext({} as DropdownCtxType);

export const useDropDown = () => useContext(DropdownCtx);

const DropdownProvider: React.FC<DropdownProviderType> = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  const onDropdownClick = () => {
    setShowMenu(!showMenu);
  };

  const onDropdownClose = () => {
    setShowMenu(false);
  };

  const value = {
    onDropdownClick,
    onDropdownClose,
    showMenu,
  };

  return <DropdownCtx.Provider value={value}>{children}</DropdownCtx.Provider>;
};

export default DropdownProvider;
