import React from "react";
import { getSuccessBannerCopy } from "../../../utils/getSuccessBannerCopy";
import useFormSuccessBannerStore from "../../../sp-state/useFormSuccessBanner";

import CloseIcon from "../../SVGIcons/CloseIcon/CloseIcon";
import styles from "./GenericFormSuccessBanner.module.scss";

interface IGenericFormSuccessBanner {
  location: string;
  [key: string]: any;
}
const GenericFormSuccessBanner: React.FC<IGenericFormSuccessBanner> = ({
  location,
  ...rest
}) => {
  const { resetFormSuccessBanner } = useFormSuccessBannerStore(state => state);

  const isGaramond =
    location.includes("/pollen/") ||
    location.includes("/blog/") ||
    location.includes("/category/");

  const pollenDmBannerStyles = location.includes("/pollen-digital-magazine")
    ? styles.pollenDmInfoBanner
    : "";

  const demoBannerStyles = location.includes("/demo") ? styles.demoBanner : "";
  const gatedBannerStyles = location.includes("/enterprise/")
    ? styles.gatedBanner
    : "";

  const bannerContentClasses = `${
    styles.infoBanner
  } ${pollenDmBannerStyles} ${demoBannerStyles} ${
    isGaramond ? styles["garamond"] : ""
  } ${gatedBannerStyles}`;

  const copyOpts = {
    "/pollen/": "Thanks for signing up to our newsletter!",
    "/blog/": "Thanks for signing up to our newsletter!",
    "/category/": "Thanks for signing up to our newsletter!",
    "/resource/":
      "Thank you for downloading this resource! Check your email shortly.",
    "/enterprise/": "Thank you for downloading! Check your email shortly.",
    "/events/": "You're all set! Enjoy the webinars.",
    "/pollen-digital-magazine":
      "Thank you for downloading Pollen! Check your email for your free copy.",
    "/demo":
      "Thanks for sharing! Check out all the features we offer for practices like yours.",
  };

  const copy = getSuccessBannerCopy(copyOpts, location);

  const handleClick = () => resetFormSuccessBanner();

  return (
    <div {...rest} className={styles.bannerWrapper}>
      <div className={bannerContentClasses}>
        {copy}
        <button onClick={handleClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default GenericFormSuccessBanner;
