import { Script } from "gatsby";
import * as React from "react";

type SchemaScriptPropsType = {
  jsonScript: string | null;
};

const SchemaScript: React.FunctionComponent<SchemaScriptPropsType> = ({
  jsonScript,
}) => {
  return jsonScript ? (
    <Script
      data-testid="script:schema-script"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: jsonScript }}
    />
  ) : null;
};

export default SchemaScript;
