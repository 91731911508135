import { create } from "zustand";

const initialState = {
  modals: {},
};

const useModalsStore = create<Modals.UseModalsStore>((set, get) => ({
  ...initialState,
  setModals: modals => set({ modals }),
  getModal: trigger => {
    const modals = get().modals;

    for (let key in modals) {
      if (modals[key].ctaId === trigger) {
        return modals[key];
      }
    }
    return;
  },
}));

export default useModalsStore;
