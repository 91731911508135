import { create } from "zustand";

const initialState = {
  modalFormSuccessMsg: false,
};

const useModalFormSuccessMsgStore = create<Modals.UseModalFormSuccessMsg>(
  set => ({
    ...initialState,
    setModalFormSuccessMsg: val => set({ modalFormSuccessMsg: val }),
    resetModalFormSuccessMsg: () => set(initialState),
  })
);

export default useModalFormSuccessMsgStore;
