const generateUuId = (len: number = 16) => {
  const characters = "qwertyuio1p2a3s4d5f6g7h8j9k0l=z-xcvbnm";
  let uui = "";

  for (let idx = 0; idx < len; idx++) {
    const character = Math.floor(Math.random() * characters.length);
    uui += characters[character];
  }

  return uui;
};

export default generateUuId;
