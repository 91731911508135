import { create } from "zustand";

type UseEmailStoreType = {
  email: string;
  setEmail: (val: string) => void;
};

export const useEmailStore = create<UseEmailStoreType>(set => ({
  email: "",
  setEmail: val => set(_ => ({ email: val })),
}));
