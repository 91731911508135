import { create } from "zustand";

const initialState = {
  modalExit: {
    handleMouseLeave: (e: MouseEvent) => {},
  },
};

const useModalExitStore = create<Modals.UseModalExit>(set => ({
  ...initialState,
  resetModalExit: () => set(initialState),
  setModalExit: fnc =>
    set(state => ({
      modalExit: {
        ...state.modalExit,
        handleMouseLeave: fnc,
      },
    })),
}));

export default useModalExitStore;
