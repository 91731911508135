export function addClass(el: HTMLElement, class_name: string) {
  if (el) {
    el.classList.add(class_name);
  }
}

export function removeClass(el: HTMLElement, class_name: string) {
  if (el) {
    el.classList.remove(class_name);
  }
}

export function getFormattedTime() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = ("0" + (today.getMonth() + 1)).slice(-2);
  const dd = today.getDate();
  const hour = today.getUTCHours();
  const minutes = today.getUTCMinutes();
  const seconds = today.getUTCSeconds();

  const formattedTime = `${yyyy}-${mm}-${dd} ${hour}:${minutes}:${seconds}`;
  return formattedTime;
}
